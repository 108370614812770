<template>
  <div class="border">
    <v-card flat tile>
      <v-card-title class="text-capitalize font-weight-light">
        <v-spacer />
        <v-icon large>{{ Icons.twoFA }}</v-icon>
        {{ $t('commons.titles.twoFaAuthentication') }}
        <v-spacer />
      </v-card-title>
      <v-divider class="mx-10" />

      <v-card-subtitle class="text-center">
        {{ $t('model.TwoFactorAuthentication.description') }}
      </v-card-subtitle>
      <v-card-text class="text-center">
        <div v-if="!My.state.twoFA.isEnabled">
          <div v-html="authAppMessage" />
          <div class="pt-4">
            <span class="font-weight-light text--secondary">
              <v-icon small color="error" class="mr-1">{{ Icons.alertCircle }}</v-icon>
              <span v-if="forceTwoFASetup">
                {{ $t('model.TwoFactorAuthentication.forceEnable') }}
              </span>
              <span v-else>
                {{ $t('model.TwoFactorAuthentication.required') }}
              </span>
            </span>
          </div>
        </div>
        <div v-else>
          <v-alert color="green" class="success-alert" elevation="2" text>
            <span
              ><v-icon color="success" class="mr-2">{{ Icons.checkCircle }}</v-icon
              >{{ $t('model.TwoFactorAuthentication.enabled') }}</span
            >
          </v-alert>
        </div>
      </v-card-text>
      <v-card-actions class="setup-2fa-btn-container">
        <v-btn
          v-if="!My.state.twoFA.isEnabled"
          :input-value="My.state.twoFA.isEnabled"
          :disabled="!!My.state.twoFA.isEnabled"
          v-on:click="enableTwoFactor"
          bottom
          large
          color="blue"
          class="px-8 py-6"
        >
          {{ $t('model.TwoFactorAuthentication.setUpBtnText') }}
        </v-btn>
      </v-card-actions>
      <v-dialog
        v-model="My.state.twoFA.showQRCodeModal"
        class="ma-16 max-z-index"
        max-width="530px"
        min-width="400px"
        persistent
      >
        <v-card class="pa-4">
          <v-card-text class="pa-6">
            <v-row>
              <v-col cols="12" class="text-center">
                <h1>{{ $t('model.TwoFactorAuthentication.setUpTitle') }}</h1></v-col
              >
              <v-col cols="12">
                <v-divider></v-divider>
              </v-col>
              <v-col cols="12">
                <v-row class="align-center">
                  <v-col>
                    <v-progress-circular
                      v-if="My.state.twoFA.isLoadingQrCode"
                      indeterminate
                      width="1"
                      size="100"
                      color="grey"
                    ></v-progress-circular>
                    <img
                      v-if="!My.state.twoFA.isLoadingQrCode && My.state.twoFA.qrCodeUrl"
                      :src="My.state.twoFA.qrCodeUrl"
                      alt="2FA QR Code"
                    />
                  </v-col>
                  <v-col>{{ $t('model.TwoFactorAuthentication.stepOne') }}</v-col>
                </v-row>
              </v-col>
              {{ $t('model.TwoFactorAuthentication.enterCode') }}
              <v-col cols="12" style="display: flex; justify-content: center">
                <v-chip
                  v-if="My.state.twoFA.secretCode"
                  label
                  small
                  :class="$vuetify.theme.dark ? 'dark' : 'light'"
                  class="mt-2"
                  style="margin: 0 auto"
                >
                  {{ My.state.twoFA.secretCode }}
                </v-chip>
              </v-col>
              <v-col cols="12">
                <v-divider></v-divider>
              </v-col>
              <v-col cols="12">
                {{ $t('model.TwoFactorAuthentication.stepTwo') }}
              </v-col>
              <v-col cols="12" class="pb-0 text-center">
                <v-text-field
                  autofocus
                  v-bind:label="$t('model.TwoFactorAuthentication.tokenFieldLabel')"
                  :value="My.state.twoFA.token"
                  @input="actions.updateAttribute({ key: 'twoFA.token', value: $event })"
                  :error-messages="tokenInvalidMsg || tokenSomethingWentWrongMsg"
                  :error="!!tokenInvalidMsg || !!tokenSomethingWentWrongMsg"
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="text-left pa-0">
                <v-btn @click="closeQRCodeModal" class="twoFa-setup-modal-btn">{{
                  $t('commons.operation.cancel')
                }}</v-btn>
              </v-col>
              <v-col class="text-right pa-0">
                <v-btn
                  :disabled="!tokenFieldValidLength"
                  @click="confirmEnableTwoFactorToken"
                  color="blue"
                  class="twoFa-setup-modal-btn"
                >
                  <v-progress-circular
                    v-if="My.state.twoFA.isValidatingToken"
                    indeterminate
                    width="1"
                    size="25"
                    color="white"
                  ></v-progress-circular>
                  <span v-else>{{ $t('commons.operation.confirm') }}</span></v-btn
                >
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-card>
  </div>
</template>
<script>
import { ref, computed, inject } from '@vue/composition-api';
import _My from '../../store/private/My';

export default {
  name: 'two-fa-setup',
  setup(props, context) {
    const application = inject('Application');
    const forceTwoFASetup = application.state.authentication.forceTwoFASetup;
    const My = _My(props, context);
    const tokenMinLength = ref(6);
    const tokenFieldValidLength = computed(() => {
      return My.state.twoFA.token?.length >= tokenMinLength.value;
    });
    const tokenSomethingWentWrongMsg = computed(() => {
      return My.state.twoFA.tokenSomethingWentWrongMsg || null;
    });
    const tokenInvalidMsg = computed(() => {
      return My.state.twoFA.tokenInvalidMsg || null;
    });
    const isValidatingToken = computed(() => {
      return My.state.twoFA.isValidatingToken || false;
    });
    const authAppMessage = computed(() => {
      return context.root.$t('model.TwoFactorAuthentication.authAppMsg', {
        authyApp1Link: createLink(
          context.root.$t('model.TwoFactorAuthentication.authyApp1Link.text'),
          context.root.$t('model.TwoFactorAuthentication.authyApp1Link.href')
        ),
        authyApp2Link: createLink(
          context.root.$t('model.TwoFactorAuthentication.authyApp2Link.text'),
          context.root.$t('model.TwoFactorAuthentication.authyApp2Link.href')
        ),
      });
    });

    function createLink(text, href) {
      return `<a href="${href}" target="_blank">${text}</a>`;
    }

    return {
      forceTwoFASetup,
      ...My,
      My,
      tokenFieldValidLength,
      tokenSomethingWentWrongMsg,
      tokenInvalidMsg,
      isValidatingToken,
      authAppMessage,
    };
  },
  methods: {
    openQRCodeModal() {
      this.My.actions.resetTwoFaState();
      this.My.state.twoFA.showQRCodeModal = true;
    },
    closeQRCodeModal() {
      this.My.state.twoFA.showQRCodeModal = false;
    },
    async enableTwoFactor() {
      this.openQRCodeModal();
      await this.My.actions.enableTwoFactor();
    },
    async confirmEnableTwoFactorToken() {
      await this.My.actions.confirmEnableTwoFactorToken();
    },
  },
};
</script>
<style lang="scss">
.setup-2fa-btn-container {
  justify-content: center;
}

.twoFa-setup-modal-btn {
  min-width: 120px !important;
  padding: 10px 25px !important;
  height: 40px;
}

.success-alert {
  text-align: center;
  max-width: 200px;
  margin: 0 auto;
}
</style>
