import _ from 'lodash';
import { reactive } from '@vue/composition-api';
import HttpClient from '../HttpClient';
import HttpClientV2 from '../HttpClientV2';
import Vue from 'vue';
import Token from '../Token';
import en from '@/messages/en';

const className = 'Affiliate';
const userData = Token.decodeToken();
const user = JSON.parse(localStorage.getItem('USER_KEY'));
const somethingWentWrongMsg = en.commons.error.somethingWentWrong;

export default function My(props, context) {
  const state = reactive({
    loading: false,
    item: {},
    accountInformationLoading: false,
    phone: '',
    country: '',
    zipCode: '',
    city: '',
    adress: '',
    password: '',
    newPassword: '',
    confirmPassword: '',
    changePasswordLoading: false,
    changePasswordError: false,
    changeNameLoading: false,
    resetApiKeyLoading: false,
    changeBillingSettingsLoading: false,
    changeBillingSettingsError: false,
    message: null,
    messageLoading: false,
    messageSuccess: false,
    messageError: false,
    paymentMethods: [],
    isAdmin: userData.admin,
    notMatchCurrentPassword: false,
    name: '',
    twoFA: {
      isEnabled: !!user.twoFactorEnabled,
      qrCodeUrl: null,
      secretCode: null,
      isLoadingQrCode: false,
      isValidatingToken: false,
      token: null,
      tokenInvalidMsg: null,
      tokenSomethingWentWrongMsg: null,
      showQRCodeModal: false,
      showPasswordTokenModal: false,
      showBillingTokenModal: false,
    },
  });
  let paymentMethodsRaw = [];

  const actions = {
    resetPasswordError() {
      state.notMatchCurrentPassword = false;
      state.changePasswordError = false;
    },
    async sendMessage() {
      if (context.refs.form) {
        let validation = context.refs.form.validate();
        if (!validation) return;
      }
      state.messageLoading = true;
      try {
        await HttpClientV2.callFunctionV2('POST', 'affiliate/sendMessage', {
          message: state.message,
        });
        state.messageSuccess = true;
        state.message = null;
      } catch (error) {
        state.messageError = true;
      } finally {
        state.messageLoading = false;
      }
    },
    async loadInfo() {
      try {
        _.set(state, 'loading', true);
        _.set(state, 'item', await HttpClientV2.callFunctionV2('GET', 'affiliate/info', null));
        const response = await HttpClientV2.callFunctionV2('GET', 'payment-method', {
          status: true,
          page: 0,
          limit: 9999,
        });
        const noPaymentSelected = { _id: null, name: 'NO PAYMENT SELECTED', currency: '' };
        response.paymentMethods.unshift(noPaymentSelected);
        paymentMethodsRaw = response.paymentMethods;
        const paymentMethodsArray = response.paymentMethods.map((paymentMethod) => {
          return `${paymentMethod.name} ${paymentMethod.currency}`.trim();
        });
        _.set(state, 'paymentMethods', paymentMethodsArray);
        if (state.item.paymentMethodId !== undefined) {
          const paymentMethod = paymentMethodsRaw.find(
            (paymentMethod) => String(state.item.paymentMethodId) === String(paymentMethod._id)
          );
          if (paymentMethod) {
            _.set(
              state,
              'item.paymentMethod',
              `${paymentMethod.name} ${paymentMethod.currency}`.trim()
            );
          }
        }
        if (!Array.isArray(state.item.conversionNotifications)) {
          Vue.set(state.item, 'conversionNotifications', []);
        }
      } catch (error) {
        _.set(state, 'item', {});
        _.set(state, 'adress', '');
        _.set(state, 'country', []);
        _.set(state, 'zipCode', '');
        _.set(state, 'phone', '');
      } finally {
        _.set(state, 'loading', false);
      }
    },

    async changeAccountInformation() {
      state.accountInformationLoading = true;
      try {
        await HttpClientV2.callFunctionV2('PUT', 'affiliate', {
          adress: state.item.adress,
          city: state.item.city,
          zip: state.item.zip,
          _country: state.item.country,
          phone: state.item.phone,
        });
        return await actions.loadInfo();
      } finally {
        state.accountInformationLoading = false;
      }
    },
    async changeName() {
      state.changeNameLoading = true;
      try {
        await HttpClientV2.callFunctionV2('PUT', 'affiliate', {
          name: state.item.name,
        });
        return await actions.loadInfo();
      } finally {
        state.changeNameLoading = false;
      }
    },
    async changeThumbnail(thumbnail) {
      try {
        await HttpClientV2.callFunctionV2('PUT', 'affiliate', {
          thumbnail: thumbnail,
        });
        HttpClient.changeThumbnail(thumbnail);
        return await actions.loadInfo();
      } finally {
        // Why this is empty?
      }
    },
    async changePassword(token) {
      if (context.refs.changePassword) {
        let validation = context.refs.changePassword.validate();
        if (!validation) return;
      }
      state.changePasswordLoading = !token;
      state.twoFA.isValidatingToken = true;
      try {
        this.resetPasswordError();
        const payload = {
          _id: userData.userId ?? userData._id,
          password: state.password,
          newPassword: state.newPassword,
        };
        if (token) {
          state.twoFA.token = token;
          payload.twoFactorAuthenticationToken = token;
        }
        const result = await HttpClientV2.callFunctionV2(
          'PUT',
          'affiliate/changePassword',
          payload
        );
        if (result.status === 200) {
          state.twoFA.showPasswordTokenModal = false;
          actions.resetTwoFaState();
          await HttpClient.singOut();
          window.location.href = '/';
        } else if (result.response.data.message === 'Password does not match current password.') {
          state.changePasswordError = true;
          state.notMatchCurrentPassword = true;
        } else if (result.response.data.message.includes('twoFactorAuthenticationToken required')) {
          state.twoFA.showPasswordTokenModal = true;
        } else if (result.response.data.message.includes('Invalid 2FA token')) {
          state.twoFA.tokenInvalidMsg = result.response.data.message;
        } else {
          state.changePasswordError = true;
          state.twoFA.showPasswordTokenModal = false;
          actions.resetTwoFaState();
        }
      } catch (error) {
        state.changePasswordError = true;
        state.twoFA.showPasswordTokenModal = false;
        actions.resetTwoFaState();
      } finally {
        state.changePasswordLoading = false;
        state.twoFA.isValidatingToken = false;
      }
    },
    async resetApiKey() {
      state.resetApiKeyLoading = true;
      try {
        await HttpClientV2.callFunctionV2('PUT', 'affiliate/reset-api-key', {});
        await actions.loadInfo();
      } finally {
        state.resetApiKeyLoading = false;
      }
    },
    async enableTwoFactor() {
      _.set(state, 'twoFA.isLoadingQrCode', true);
      try {
        await HttpClientV2.callFunctionV2('GET', '2fa/enable-two-factor', {}).then((result) => {
          _.set(state, 'twoFA.qrCodeUrl', result.qrCode);
          _.set(state, 'twoFA.secretCode', result.secret);
        });
      } catch (error) {
        //
      } finally {
        _.set(state, 'twoFA.isLoadingQrCode', false);
      }
    },
    async confirmEnableTwoFactorToken() {
      _.set(state, 'twoFA.isValidatingToken', true);
      try {
        const result = await HttpClientV2.callFunctionV2('POST', '2fa/enable-confirm', {
          token: state.twoFA.token,
        });
        if (result.status === 200) {
          state.twoFA.showQRCodeModal = false;
          state.twoFA.isEnabled = true;
          setTimeout(async () => {
            await HttpClient.singOut();
            window.location.href = '/';
          }, 700);
        } else if (result.response.data.message.includes('Invalid 2FA token')) {
          _.set(state, 'twoFA.tokenInvalidMsg', result.response.data.message);
        } else {
          _.set(state, 'twoFA.tokenSomethingWentWrongMsg', somethingWentWrongMsg);
        }
      } catch (error) {
        //
      } finally {
        _.set(state, 'twoFA.isValidatingToken', false);
      }
    },
    async changeBillingSettings(token) {
      if (context.refs.changeBillingSettings) {
        let validation = context.refs.changeBillingSettings.validate();
        if (!validation) return;
      }
      state.changeBillingSettingsLoading = !token;
      state.twoFA.isValidatingToken = true;
      const {
        paymentMethod,
        wireTransferBeneficiary,
        wireTransferBankName,
        wireTransferBankAddress,
        wireTransferIBAN,
        wireTransferSWIFT,
        paypalEmail,
        paxumEmail,
        payoneerEmail,
        walletAddress,
        vatTaxID,
        _cryptoCurrency,
        _cryptoBlockchain,
        paymentMethodId,
      } = state.item;
      try {
        const payload = {
          paymentMethod,
          wireTransferBeneficiary,
          wireTransferBankName,
          wireTransferBankAddress,
          wireTransferIBAN,
          wireTransferSWIFT,
          paypalEmail,
          paxumEmail,
          payoneerEmail,
          walletAddress,
          vatTaxID,
          _cryptoCurrency,
          _cryptoBlockchain,
          paymentMethodId,
        };
        if (token) {
          state.twoFA.token = token;
          payload.twoFactorAuthenticationToken = token;
        }
        await HttpClientV2.callFunctionV2('PUT', 'affiliate', payload).then(async (result) => {
          if (result.status === 200) {
            state.changeBillingSettingsError = false;
            state.twoFA.showBillingTokenModal = false;
            actions.resetTwoFaState();
            await actions.loadInfo();
          } else if (
            result.response.data.message.includes('twoFactorAuthenticationToken required')
          ) {
            state.twoFA.showBillingTokenModal = true;
          } else if (result.response.data.message.includes('Invalid 2FA token')) {
            state.twoFA.tokenInvalidMsg = result.response.data.message;
          } else {
            state.changeBillingSettingsError = true;
            state.twoFA.showBillingTokenModal = false;
            actions.resetTwoFaState();
          }
        });
      } catch (error) {
        state.changeBillingSettingsError = true;
        state.twoFA.showBillingTokenModal = false;
        actions.resetTwoFaState();
      } finally {
        state.changeBillingSettingsLoading = false;
        state.twoFA.isValidatingToken = false;
      }
    },
    async changeThirdPartyTracking() {
      if (context.refs.changeThirdPartyTracking) {
        let validation = context.refs.changeThirdPartyTracking.validate();
        if (!validation) return;
      }
      let { conversionNotifications } = state.item;
      conversionNotifications = conversionNotifications.map((notification) => {
        return { ...notification, isNew: undefined };
      });
      try {
        await HttpClient.runProcess(className, 'changeThirdPartyTracking', {
          conversionNotifications,
        });
        await actions.loadInfo();
      } catch (error) {
        // Why this is empty?
      }
    },
    async updateAttribute({ key, value }) {
      switch (key) {
        case 'item.paymentMethod': {
          const pm = paymentMethodsRaw.find(
            (paymentMethod) => value === `${paymentMethod.name} ${paymentMethod.currency}`.trim()
          );
          _.set(state, 'item.paymentMethodId', pm._id);
          break;
        }
        case 'item.payoneerEmail':
          value = value ? value.trim() : value;
          break;
      }
      _.set(state, `${key}`, value);
    },
    addToArrayNested({ key, value }) {
      let attribute = _.get(state.item, key);
      if (_.isNil(attribute)) {
        attribute = [];
      }
      if (Array.isArray(attribute)) {
        attribute.push(value);
        _.set(state.item, key, attribute);
      }
    },
    updateOnArrayNested({ key, index, value }) {
      let attribute = _.get(state.item, key);
      if (!_.isNil(attribute)) {
        if (Array.isArray(attribute) && index > -1 && index < attribute.length) {
          attribute[index] = value;
          _.set(state.item, key, attribute);
        }
      }
    },
    updateAttributeOnArrayNested({ key, index, attributeKey, attributeValue }) {
      let attribute = _.get(state.item, key);
      if (!_.isNil(attribute)) {
        if (Array.isArray(attribute) && index > -1 && index < attribute.length) {
          attribute[index][attributeKey] = attributeValue;
          _.set(state.item, key, attribute);
        }
      }
    },
    deleteOnArrayNested({ key, index }) {
      let attribute = _.get(state.item, key);
      if (!_.isNil(attribute)) {
        if (Array.isArray(attribute) && index > -1 && index < attribute.length) {
          attribute.splice(index, 1);
          _.set(state.item, key, attribute);
        }
      }
    },
    resetTwoFaState() {
      _.set(state, 'twoFA.qrCodeUrl', null);
      _.set(state, 'twoFA.isLoadingQrCode', false);
      _.set(state, 'twoFA.isValidatingToken', false);
      _.set(state, 'twoFA.token', null);
      _.set(state, 'twoFA.tokenInvalidMsg', null);
      _.set(state, 'twoFA.tokenSomethingWentWrongMsg', null);
    },
  };
  return { state, actions };
}
